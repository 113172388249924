import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              summary
              date(formatString: "DD MMMM, YYYY")
              attachments {
                childImageSharp {
                  fluid(maxWidth: 300, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `)

  const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    background: linear-gradient(rgb(34, 34, 34, 0.1), rgb(34, 34, 34, 0.8));
    width: 100%;
    height: 100%;
    /* border: 1px solid #ccc; */
  `

  return (
    <Layout>
      <SEO title="Home" />
      <div
        css={css`
          margin: 0 auto;
          max-width: 1280px;
          padding: 0px 1.0875rem 1.45rem;
        `}
      >
        <div
          css={css`
            padding: 3rem 0 1rem;
          `}
        >
          <h1
            css={css`
              font-weight: normal;
            `}
          >
            Web design
            <br />
            &amp; development portfolio
          </h1>
        </div>

        <Global
          styles={css`
            a > h2 {
              color: #1a645c;
            }
          `}
        />
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
          `}
        >
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <div
              key={node.id}
              css={css`
                @media only screen and (min-width: 600px) {
                  margin-right: 3%;
                  width: 30%;
                }
                margin-bottom: 2rem;
                background-color: #c9f3ef;
                border-bottom: 0.3rem solid #1a645c;
              `}
            >
              <Link
                css={css`
                  text-decoration: none;
                `}
                to={node.fields.slug}
              >
                <div
                  css={css`
                    position: relative;
                    display: inline-block;
                    width: 100%;
                  `}
                >
                  <Img
                    css={css`
                      display: block !important;
                      padding-top: 56.25%;
                      height: 0;
                    `}
                    imgStyle={{
                      objectPosition: "center top",
                    }}
                    fluid={
                      node.frontmatter.attachments[0].childImageSharp.fluid
                    }
                  />
                  <ImgOverlay />
                </div>
                <h2
                  css={css`
                    padding: 0.5rem 0.8rem 1rem;
                    font-weight: normal;
                    margin: 0;
                    font-size: 1.8em;
                  `}
                >
                  {node.frontmatter.title}
                </h2>
                <p
                  css={css`
                    color: #000;
                    padding: 0 0.8rem;
                    font-size: 0.9em;
                  `}
                >
                  {node.frontmatter.summary}
                </p>
                {/*<p>{node.excerpt}</p>*/}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
